body, html {
  height: 100%!important;
  font-family: Roboto, Ubuntu, Arial, serif;
  padding: 0;
  margin: 0;
}
body{
  background-image: url(/assets/bg.png);
  background-size: cover;
  /* background-position: 0px; */
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
}
div {
  box-sizing: border-box;
  position: relative;
}

  .button {

    .ready {
      background: #3A5275!important;
      border-radius: 6px!important;

    }
  }

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0 24px 0 24px!important;
}

.mat-horizontal-stepper-header-container {
  display: none!important;
}

img.parking-svg {
  height: 1645px;
  position: absolute;
  top: -150px;
  left: -590px;
}

.grey {
  color: #CACACA;
}

.cb {clear: both}

/* You can add global styles to this file, and also import other style files */
